<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { EContent } from 'auth/enums/dialog-contents'
import { signUpFirstSchema } from 'auth/validation-schema'
import IconLogo from '@/assets/images/logo.svg'

const props = defineProps<{
  type: 'brand' | 'creator'
}>()

const oppositeType = computed(() =>
  props.type === 'brand' ? 'Creator' : 'Brand',
)

const emit = defineEmits<{
  (e: 'switch', value: EContent): void
}>()

const { isDialogVisible, email: storeEmail } = storeToRefs(useAuthStore())

const { submitCount, errors, handleSubmit, resetForm, defineComponentBinds } =
  useForm({
    validationSchema: signUpFirstSchema,
  })

resetForm()
const email = defineComponentBinds('email')
const { isSubmitted: isFormSubmitted } = useValidation(submitCount)

const oppositeContent = computed(() =>
  props.type === 'brand'
    ? EContent.SIGN_UP_FIRST_CREATOR
    : EContent.SIGN_UP_FIRST_BRAND,
)

const isSubmitDisabled = computed(
  () => isFormSubmitted.value && !!errors.value.email,
)

const proceedToSecondStep = handleSubmit((values) => {
  storeEmail.value = values.email
  emit(
    'switch',
    props.type === 'creator'
      ? EContent.SIGN_UP_SECOND_CREATOR
      : EContent.SIGN_UP_SECOND_BRAND,
  )
})
</script>

<template>
  <form
    class="flex flex-col items-center"
    novalidate
    @submit.prevent="proceedToSecondStep"
  >
    <IconLogo
      aria-label="Branded Content logo"
      class="w-[137px]"
      role="img"
    />
    <h2 class="my-8 text-center text-4xl font-normal capitalize">
      {{ type }} Sign Up
    </h2>
    <AuthViewSignInWithSocial />
    <div class="flex w-full flex-col items-start">
      <div class="p-float-label w-full">
        <InputText
          id="email"
          v-bind="email"
          type="email"
          :class="[
            'w-full',
            {
              'p-invalid': errors.email && isFormSubmitted,
            },
          ]"
        />
        <label for="email">Enter your email</label>
      </div>
      <small
        v-if="errors.email && isFormSubmitted"
        class="p-error mt-1 text-left"
      >
        {{ errors.email }}
      </small>
      <Button
        type="submit"
        class="mb-4 mt-6 w-full justify-center [&>.p-button-label]:flex-none"
        :disabled="isSubmitDisabled"
        label="Continue"
      />
    </div>
    <p
      class="mb-4 flex w-full flex-wrap justify-center border-b border-gray-500 pb-4 font-normal text-gray-100"
    >
      Already have an account?
      <Button
        class="p-button-link ml-1 p-0 font-normal"
        type="submit"
        label="Log In"
        @click="$emit('switch', EContent.SIGN_IN)"
      />
    </p>
    <Button
      class="p-button-outlined mb-4 w-full"
      :label="`Sign Up as a ${oppositeType}`"
      @click="$emit('switch', oppositeContent)"
    />
    <p class="m-0 text-center text-xs font-normal text-gray-200">
      By joing, you agree to our
      <NuxtLink
        to="/terms-of-use"
        @click.prevent="isDialogVisible = false"
        >terms of service</NuxtLink
      >. As well as to receive occasional emails from us.
    </p>
  </form>
</template>
